import React from 'react';
import CountryGuide from '../../../../components/guides/CountryComponent';
import Footer from '../../../../components/FooterComponent';

import { Vietnam } from '../../../../guides/countries/vietnam/vietnam';

function VietnamPage(props) {
    return (
        <div>
            <CountryGuide country={ Vietnam } pathname={ props.location.pathname } urlBack="/travel-guides/asia" />
            <Footer />
        </div>
    );
}

export default VietnamPage;
