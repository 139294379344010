import React from 'react';

export const Vietnam = {
    "name": "Vietnam",
    "link": "vietnam",
    "continent": "Asia",
    "caption": "Land Of The Ascending Dragon",

    "description": "Vietnam is a land of full contrasts, from a frenetic capital city filled with people on the streets to the amazing landscapes of endless rice paddies, there is not a better place to travel than Vietnam. This fascinating nation is full of surprises and has everything you look for on a vacation. An amazing and unique culture, full of history and places to search, fantastic nature landscapes, paradise beaches, crowded and lively cities, quite and magical places through the mountains and an amazing cuisine.",

    "thumb": "https://live.staticflickr.com/65535/50380691802_85e70e5e24_t.jpg",

    "images": [
        "https://live.staticflickr.com/65535/50380691802_bf0091b8f5_o.jpg",


        "https://images.unsplash.com/photo-1526139334526-f591a54b477c?ixlib=rb-1.2.1&w=1280&q=80",
        "https://cdn.pixabay.com/photo/2018/06/22/06/11/rice-field-3490060_1280.jpg",
        "https://images.unsplash.com/photo-1521993117367-b7f70ccd029d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://images.unsplash.com/photo-1528127269322-539801943592?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://cdn.pixabay.com/photo/2015/11/18/00/49/sunrise-1048516_1280.jpg"
    ],

    "whenVisit": <p>
        The high season occurs between Summer and Winter. This is considered the <b>dry season</b>, when the country sees the least amount of rain, and the heat is not yet uncomfortable, making this period the best time to visit in terms of weather.
        The downside is the prices that tend to increase with the demand.
        <br/><br/>
        Personally, we recommend the months from <b>February to April</b>, which is slightly before the masses of tourist arrive.
        You can have a nice cost-benefit with a good weather and not a huge amount of tourists spoiling your experience.
    </p>,

    "mobility": <p>
        Vietnam offers an array of ways to get around. You may favor flying straight from point to point, opt for motorbike rides, or take the overland routes of trains and buses, soaking in the sights across the way.
        Within the cities there are plenty of taxis, buses, and cycles; and out in the countryside, cycling is often a good choice.
        <br/><br/>
        For those short on time and that acknowledge the bad state of the roads in the country, using <b>internal flights</b> is a good and fast way to move.
        The flight prices are very reasonable, with 3 companies operating (Vietnam Airlines, Vietjet and Jetstar), you can save a lot of time if you only want to see the main spots.
        <br/><br/>
        <b>Trains</b> are an insightful and relaxing option to travel across the country. Averaging no more than 50km per hour, you will need a lot of time to go from one point to another.
        The network is managed by Vietnam Railways, and you can buy tickets at counters in Vietnam's train stations days or months in advance of your trip. They are also sold through many travel agencies and online through <a href="https://dsvn.vn/" target="_blank" rel="noopener noreferrer">Vietnam's official train ticketing website</a>.
        <br/><br/>
        If you intend on traveling from Hanoi to Ho Chi Minh City you can also opt for the <b>Reunification Express</b>. It’s a 36-hour scenic ride that makes stops in Hue, Danang, Nha Trang and Phan Thiet.
        Also, there are four seat possibilities to choose from: hard seat, soft seat, hard sleeper, and soft sleeper. The cabins are divided into four berths with simple bedding provided.
        <br/><br/>
        If an adventure is what you seek, then <b>buy a motorbike</b> starting in either Hanoi or Ho Chi Minh City and drive to the other point. You will need almost a <b>month</b> to achieve this, and you will be taken off the beaten path to discover the true identity of the Vietnamese culture, it's truly and incredible experience.
        This is a very popular route made by many travelers, and they usually sell their motorbike at the end of the route to some other traveler ready to take on the challenge.
        <br/><br/>
        If you want a budget option for traveling there are <b>buses</b> connecting all cities.
    </p>,

    "culture": <p>
        Vietnam has a unique culture. The people here are still conservative and fairly tolerant, so it’s always the best practice to follow the rules and avoid some simple behaviors: Nudity is forbidden in all places, including beaches and in religious sites avoid showing much of the skin.
        Passing around cigarettes (to men only) is always appreciated in negotiations, <b>bargaining</b> and so forth.
        <br/><br/>
        The main language is <b>Vietnamese</b>. You can learn some important expressions that may be useful, like “thank you” or “please”. In the major cities and touristic spots <b>English</b> should be enough. However, sometimes it can be difficult to communicate with the locals due to language barriers. Always be patient and try to gesticulate, and keep a friendly expression.
        <br/><br/>
        Officially, homosexuality is still viewed as a “social evil”, in conjunction with drugs and prostitution. Nevertheless, there is no law explicitly banning homosexual activity and, as long as it is not practiced explicitly, it is largely ignored.
    </p>,

    "survival": [
        {
            "title": "Health",
            "description": <p>
                Prior to traveling to Vietnam, be sure to acquire a <b>good insurance</b> for your trip.
                You will be outside your home country, some of you in a place far away, and for any health related event or even the need to return home earlier could result in a big expense.
                <br/><br/>
                Personally we recommend the <a href="https://www.iatitravelinsurance.co.uk/" target="_blank" rel="noopener noreferrer">IATI insurances</a>. We have already used it in a few trips and never had any kind of problems with the service.
                <br/><br/>
                Also, 1 or 2 months before your trip, you should consult your doctor or local travel clinic for the latest information on <b>health risks</b> in Vietnam and to get any <b>vaccinations</b> you may need.
                Usually, for Vietnam it is recommended the following vaccines:
                <br/>
                <ul style={{ marginTop: '16px' }}>
                    <li>Typhoid Fever;</li>
                    <li>Hepatitis A and B;</li>
                    <li>Japanese Encephalitis (if traveling through rural areas and for more than a month).</li>
                </ul>
                You should bring a "medical kit" with products and medications to help you in the event of a disease. Remember that Vietnam is a tropical country, and you may get exposed to many germs and bacteria that are not present on your home country.
                Fever and diarrhea are very common amongst tourists, and you are pretty much guaranteed to have one. Remember that you’re going to taste a very different cuisine and your system may not be used to that.
                Drink lots of water too, but always use <b>bottled water</b>, never try to drink from the tap. As previously said, don’t be very concerned if you get diarrhea, however, if the problem persists for some days seek medical attention.
                <br/><br/>
                Diseases like malaria and dengue fever (as well as Japanese encephalitis) are present on Vietnam and are transmitted through bites of mosquitoes. This is the reason why bringing an <b>insect repellent</b> is very important.
                For all these situations, don't forget to pack the following:
                <ul style={{ marginTop: '16px' }}>
                    <li>DEET based insect repellent - important to keep mosquitoes away;</li>
                    <li>Paracetamol or Ibuprofen - for pain relief;</li>
                    <li>Sunscreen - never underestimate the sun in Vietnam;</li>
                    <li>Antihistamine - for allergy treatment;</li>
                    <li>Anti-itch ointment - for example hydrocortisone;</li>
                    <li>Diarrhea medication - for example Imodium;</li>
                    <li>Bacitracin - for wound care.</li>
                </ul>
                Some of the medication listed could be <b>difficult</b> to find in Vietnam, make sure you bring enough for your trip. The biggest cities have a number of state owned and private hospitals with English-speaking staff. Pharmacies are common in almost any Vietnamese city and also, but more rarely, in Vietnamese rural areas.
                If you have any health issue, like allergies for example, you should bring with you a doctors letter with all the information.
                <br/><br/>
                Rabies is still a problem in Vietnam. If for some reason you are bitten by an animal or licked in a wound, you <b>must</b> immediately search for medical assistance in order to take the vaccine.
            </p>
        },
        {
            "title": "Safety",
            "description": <p>
                Vietnam is a <b>safe country</b> for tourists, however petty theft is a common concern, mainly in the big cities. Abstain from using cameras and cell phones on busy streets and corners: most robberies are drive-by snatchings.
                When getting pictures on sidewalks, keep your camera close to your body and away from the flow of traffic.
                <br/><br/>
                When riding a motorbike, put your bags in the seat compartment or tie them in the front of the bike. If something gets stolen, unfortunately the law enforcements very rarely will recover your items.
                <br/><br/>
                For <b>solo female travelers</b>, Vietnam is usually a safe country. Still, you should take the typical precautions if you’re a woman traveling along, use taxis from a reputable agency and avoid being alone in the streets late.
            </p>
        },
        {
            "title": "Visas",
            "description": <p>
                When you’re planning a trip, it's important to find out if you need to secure a visa for travel to that country and how to get it.
                You can find <a href="/travel-tips/visas#vietnam">here</a> all the information that you need to know related to this topic.
            </p>
        },
        {
            "title": "Money",
            "description": <p>
                Vietnam is not an expensive country to travel. Its unit of currency is the <b>dong</b> and you will find it abbreviated as “đ”, “d” or “VND”.
                <b> American Dollar and Euro</b> (in fewer places) can also be used, and it can be useful for large bills. Tipping is not usually expected by locals, but it’s always appreciated, with normal values of 10%.
                <br/><br/>
                Temple and pagodas are usually free, and for accommodation you will find a huge variety of options, from very cheap hostels and hotels to high-end accommodation.
                Daily expenses, admission charges to historic sites, food and drinks (particularly if you do as the locals do) are low, and it is almost always possible to bargain.
            </p>
        },
        {
            "title": "Electricity",
            "description": <p>
                The electricity supply in Vietnam is <b>220 volts</b>. Plugs normally have two round pins (European style), even though you may find some sockets accepting two flat pins and even some that take three pins. Power supplies can be erratic on the sticks, so be ready for cuts and surges.
            </p>
        },
        {
            "title": "Internet",
            "description": <p>
                In all the major cities and tourist centres, it’s usual that cafes and accommodations provide WiFi. Unfortunately, some of the top-end hotels still require a hefty fee for letting you go online.
                <br/><br/>
                What we recommend when you travel is to buy a <b>SIM card</b> locally. You can get one when at the airport or in the city centres. Always choose a major operator in order to get better signal, <b>Viettel</b> is a good option. If your phone is blocked, you can also opt to buy a cheap phone.
            </p>
        }
    ],

    "itineraries": [
        {
            "name": "2 Weeks Or Less",
            "description": <p>
                With two weeks to visit, you should not try to see everything, since that way you will spend most of your time on travels. You should decide between visiting the <b>Northern</b> or <b>Southern</b> part of the country, along with some of <b>Central Vietnam</b>.
                <br/><br/>
                If you choose the <b>Northern</b> part of the country, you should base yourself in the capital city <a href="/travel-guides/asia/vietnam/hanoi">Hanoi</a>. Spend about <b>two to three days</b> here to get as much of the Vietnamese culture as possible.
                From here, you can take <b>single or multi-day tours</b> to either <a href="/travel-guides/asia/vietnam/ha-long-bay">Ha Long Bay</a>, <a href="/travel-guides/asia/vietnam/ninh-binh">Ninh Binh</a> or <a href="/travel-guides/asia/vietnam/sapa">SaPa</a>.
                <br/><br/>
                If you choose the <b>South</b> of the country, base yourself in the cosmopolitan city that is <a href="/travel-guides/asia/vietnam/ho-chi-minh-city">Ho Chi Minh City</a>. You will find a very lively, chaotic environment, where you should spend around <b>three days</b>.
                You can also take a 1-day tour to <a href="/travel-guides/asia/vietnam/mekong-delta">Mekong Delta</a>. From here, proceed north to <a href="/travel-guides/asia/vietnam/dalat">Dalat</a>, where you should spend around <b>3 days</b>.
                If you are a <b>beach lover</b>, considered either visiting the coastal towns of <a href="/travel-guides/asia/vietnam/mui-ne">Mui Ne</a> or <a href="/travel-guides/asia/vietnam/nha-trang">Nha Trang</a>.
                <br/><br/>
                For the remaining days, visit the most popular cities of <b>Central Vietnam</b>, namely <a href="/travel-guides/asia/vietnam/hoi-an">Hoi An</a> and <a href="/travel-guides/asia/vietnam/hue">Hue</a>.
            </p>
        },
        {
            "name": "3 Week Itinerary - Recommended",
            "description": <p>
                From <b>South to North</b>. This route includes all the nation's essential cultural spots and is bookended by its two greatest towns.
                You'll have time to spend at the beach and on the mountains. Ponder taking <b>internal flights</b> (or overnight trains) to save time.
                <br/><br/>
                The adventure begins in the cauldron of commerce, <a href="/travel-guides/asia/vietnam/ho-chi-minh-city">Ho Chi Minh City</a>.
                Spend at least <b>two to three days</b> exploring the markets, searching museums and eating some of Asia's best street food. You should also consider taking a <b>1 day tour</b> to <a href="/travel-guides/asia/vietnam/mekong-delta">Mekong Delta</a>.
                <br/><br/>
                Then, get a plane or train up to <a href="/travel-guides/asia/vietnam/da-nang">Da Nang</a> to access the cultured charming and culinary hot spot that is <b>Central Vietnam</b>. Explore the <b>Ba Na Hills</b> and the magic of the ancient city of <a href="/travel-guides/asia/vietnam/hoi-an">Hoi An</a>.
                This two towns certainly warrants <b>four or five days</b>, such is their charm. Enjoy <b>Hoi An's</b> unique environment, visiting its temples and <b>Old Town</b>, and relax at the nearby beach of <b>An Bang</b>. Then take the <a href="/travel-guides/asia/vietnam/hai-van-pass">Hai Van Pass</a> to the old imperial capital city of <a href="/travel-guides/asia/vietnam/hue">Hue</a>.
                On this route you will find amazing views of the coastal scenery. Once you arrive at <b>Hue</b>, spend <b>two days</b> here exploring the citadel, pagodas and tombs.
                <br/><br/>
                Next, it’s a long journey by train (or bus) to <a href="/travel-guides/asia/vietnam/phong-nha">Phong Nha</a>, where you can explore its many <b>amazing caves</b>. <b>Two days</b> here is recommended before taking another train or bus to <a href="/travel-guides/asia/vietnam/ninh-binh">Ninh Binh</a>. Here take a deep breath and let you embrace by the magnificent fields and mountains.
                Two hours away we will have the chance to check out the capital's <a href="/travel-guides/asia/vietnam/hanoi">Hanoi</a>. Explore the evocative <b>Old Quarter</b>, munch some street food and view the city's elegant architecture and cultural sights.
                From <b>Hanoi</b> book a tour to incomparable <a href="/travel-guides/asia/vietnam/ha-long-bay">Halong Bay</a>, which boasts more than 2000 limestone islands, before returning to <b>Hanoi</b>.
                <br/><br/>
                For the remaining days you may have, choose between going to <a href="/travel-guides/asia/vietnam/sapa">SaPa</a> or <a href="/travel-guides/asia/vietnam/phu-quoc">Phu Quoc</a>. If want to see more <b>amazing landscapes</b> and learn the culture of some <b>ethnic minorities</b> of Vietnam, then <a href="/travel-guides/asia/vietnam/sapa">SaPa</a> should be your choice.
                If you prefer to spend some time on the beach, head to <a href="/travel-guides/asia/vietnam/phu-quoc">Phu Quoc</a>, a <b>little paradise</b> on Vietnam, still not much overwhelmed with tourists. Here you will find the <b>best beaches</b> of the whole country.
            </p>
        },
        {
            "name": "1 Month Or More",
            "description": <p>
                Start on either <a href="/travel-guides/asia/vietnam/ho-chi-minh-city">Ho Chi Minh City</a> or <a href="/travel-guides/asia/vietnam/hanoi">Hanoi</a>. Buy a <b>motorbike</b> if you have enough experience or get ready to travel a lot on <b>buses</b>, and go to the other major city on the country.
                With 1 month on the country, you will have enough time to visit all the <b>major attractions</b> and explore some <b>off the beaten path</b> places.
            </p>
        }
    ],

    "guides": [
        {
            "title": "Northern Vietnam",
            "places": [
                {
                    "name": "Hanoi",
                    "image": "https://live.staticflickr.com/65535/50494447361_4fbe336f07_o.jpg",
                    "link": "hanoi"
                },
                {
                    "name": "Ha Long Bay",
                    "image": "https://live.staticflickr.com/65535/50493711508_43b8f93d4b_o.jpg",
                    "link": "ha-long-bay"
                },
                {
                    "name": "SaPa",
                    "image": "https://live.staticflickr.com/65535/50493711303_a8e660b87f_o.jpg",
                    "link": "sapa"
                },
                {
                    "name": "Ninh Binh",
                    "image": "https://live.staticflickr.com/65535/50493711368_42d066f2c6_o.jpg",
                    "link": "ninh-binh"
                },
                {
                    "name": "Ban Gioc Waterfall",
                    "image": "https://live.staticflickr.com/65535/50493711568_141223062b_o.jpg",
                    "link": "ban-gioc-waterfall"
                }
            ]
        },
        {
            "title": "Central Vietnam",
            "places": [
                {
                    "name": "Hoi An",
                    "image": "https://live.staticflickr.com/65535/50494414371_9ca4647465_o.jpg",
                    "link": "hoi-an"
                },
                {
                    "name": "Hue",
                    "image": "https://live.staticflickr.com/65535/50493711468_84d4d8ae53_o.jpg",
                    "link": "hue"
                },
                {
                    "name": "Da Nang",
                    "image": "https://live.staticflickr.com/65535/50494571672_9f21091f17_o.jpg",
                    "link": "da-nang"
                },
                {
                    "name": "Phong Nha",
                    "image": "https://live.staticflickr.com/65535/50494571512_c16f842d1f_o.jpg",
                    "link": "phong-nha"
                },
                {
                    "name": "Hai Van Pass",
                    "image": "https://live.staticflickr.com/65535/50494414406_4d6d6c271b_o.jpg",
                    "link":"hai-van-pass"
                }
            ]
        },
        {
            "title": "Southern Vietnam",
            "places": [
                {
                    "name": "Ho Chi Minh City",
                    "image": "https://live.staticflickr.com/65535/50494571612_d2eee158b2_o.jpg",
                    "link": "ho-chi-minh-city"
                },
                {
                    "name": "Dalat",
                    "image": "https://live.staticflickr.com/65535/50493711548_7a816be806_o.jpg",
                    "link": "dalat"
                },
                {
                    "name": "Nha Trang",
                    "image": "https://live.staticflickr.com/65535/50494414271_4a6c8282ec_o.jpg",
                    "link": "nha-trang"
                },
                {
                    "name": "Mui Ne",
                    "image": "https://live.staticflickr.com/65535/50493711428_fc59e3b6f8_o.jpg",
                    "link": "mui-ne"
                },
                {
                    "name": "Phu Quoc",
                    "image": "https://live.staticflickr.com/65535/50493711333_9369382e4d_o.jpg",
                    "link": "phu-quoc"
                },
                {
                    "name": "Mekong Delta",
                    "image": "https://live.staticflickr.com/65535/50493711458_c7248fb2f2_o.jpg",
                    "link": "mekong-delta"
                },
            ]
        }
    ],

    "destinations": [
        {
            "title": "Off The Beaten Path",
            "description": "Get out of the tourist track and visit some hidden places around Vietnam. On these spots you will find the true culture of the country and you can immerse yourself on amazing, beautiful locations yet unspoiled by tourists.",
            "places": [
                {
                    "name": "Ha Giang",
                    "description": <p>
                        This Hidden Gem in Northern Vietnam is a wonderland for bike riders and nature lovers.
                        Simply put, Ha Giang Loop is one of the most scenic rides in all of Southeast Asia, and you will drive between mountains and rivers, pass lush green landscapes of rice paddies and visit some of the most authentic tribes in Vietnam.
                        Truly, one of the best experiences, not only in Vietnam, but in all of Asia.
                    </p>,
                    "image": "https://live.staticflickr.com/65535/50494571642_0d79b8a72d_o.jpg"
                },
                {
                    "name": "Quy Nho'n",
                    "description": <p>
                        A coastal town on Southern Vietnam, it won't take too long for this hidden gem to become a big touristic spot, and it's easy to see why.
                        A peaceful fishing village, bordered by stunning beaches, an amazing landscape of mountains on the background, great and fresh seafood served on its restaurants and cheap prices, what more could you ask for?
                    </p>,
                    "image": "https://live.staticflickr.com/65535/50493711318_e6b13cf1a5_o.jpg"
                },
                {
                    "name": "Ta Xua",
                    "description": <p>
                        Step above the clouds and mesmerize at the gorgeous scenery from this place. Ta Xua is a mountain range located 240km away from Hanoi, and offers some of the most breathtaking views you will find all over the country.
                        The Ta Xua Peak, its highest point, stands at an altitude of 2865 meters. If you trek here in the morning, you will stand above the clouds, with amazing views to the landscape.
                    </p>,
                    "image": "https://live.staticflickr.com/65535/50494571457_8d583eb1eb_o.jpg"
                },
                {
                    "name": "Pu Luong",
                    "description": <p>
                        Considered Vietnam's Best Kept Secret, Pu Luong is a Nature Reserve located on the Northern part of the country, and is a wonderland for nature lovers.
                        Gorgeous landscapes, incredible trekking paths through the mountains, many ethnic minority tribes to visit and learn about their culture all make this destination an incredible destination in Vietnam.
                    </p>,
                    "image": "https://live.staticflickr.com/65535/50493711323_555f6073f2_o.jpg"
                }
            ]
        }
    ]
}
